import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { CustomerRole, appConstants } from 'src/app/services/constants';
import { hasRole } from 'src/app/shared/common';

@Component({
    templateUrl: './device-filter-dialog.component.html',
})
export class DeviceFilterDialog implements OnInit, OnDestroy {
    constants = appConstants;
    maxDate = new Date();
    deviceList: any = [];
    oemList: any = [];
    dealerList: any = [];
    clientList: any = [];
    showOem = false;
    showDealer = false;
    showClient = false;
    includeFilter: any = {};

    deviceFilterCtrl: FormControl = new FormControl();
    isFilterDeviceNone = true;
    filteredDevices: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    oemFilterCtrl: FormControl = new FormControl();
    isFilterOEMNone = true;
    filteredOEMs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    dealerFilterCtrl: FormControl = new FormControl();
    isFilterDealerNone = true;
    filteredDealers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    clientFilterCtrl: FormControl = new FormControl();
    isFilterClientNone = true;
    filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    startDateFilterCtrl: FormControl = new FormControl();
    endDateFilterCtrl: FormControl = new FormControl();
    startTimeFilterCtrl: FormControl = new FormControl();
    endTimeFilterCtrl: FormControl = new FormControl();

    filter = {
        deviceId: null,
        oemId: null,
        dealerId: null,
        clientId: null,
        fileType: null,
        startDate: null,
        endDate: null,
    };

    constructor(
        private datePipe: DatePipe,
        public dialogRef: MatDialogRef<DeviceFilterDialog>,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    async ngOnInit() {
        if (this.data['include']) {
            this.includeFilter = Object.fromEntries(
                this.data['include'].map((item) => [item, true])
            );
        }

        this.api.devices.listenActive().subscribe((devices) => {
            this.deviceList = devices;
            this.filteredDevices.next(this.deviceList.slice());
        });
        await ensureLoaded([this.api.organizations]);
        if (hasRole(CustomerRole.Administrator)) this.makeOemList();
        if (hasRole(CustomerRole.OEM)) this.makeDealerList();
        if (hasRole(CustomerRole.Dealer)) this.makeClientList();
        if (this.data['current']) this.applyCurrentFilters();

        this.deviceFilterCtrl.valueChanges.pipe().subscribe(() => {
            this.isFilterDeviceNone = this.filterList(
                this.deviceList,
                this.deviceFilterCtrl,
                this.filteredDevices,
                'identifier'
            );
        });

        this.oemFilterCtrl.valueChanges.pipe().subscribe(() => {
            this.isFilterOEMNone = this.filterList(
                this.oemList,
                this.oemFilterCtrl,
                this.filteredOEMs,
                'name'
            );
        });

        this.dealerFilterCtrl.valueChanges.pipe().subscribe(() => {
            this.isFilterDealerNone = this.filterList(
                this.dealerList,
                this.dealerFilterCtrl,
                this.filteredDealers,
                'name'
            );
        });

        this.clientFilterCtrl.valueChanges.pipe().subscribe(() => {
            this.isFilterClientNone = this.filterList(
                this.clientList,
                this.clientFilterCtrl,
                this.filteredClient,
                'name'
            );
        });

        if (this.filter.startDate) {
            const startDate = new Date(this.filter.startDate);
            const timezoneOffsetS = startDate.getTimezoneOffset() * 60000;
            this.filter.startDate = new Date(
                startDate.getTime() - timezoneOffsetS
            );
            this.startDateFilterCtrl.setValue(new Date(this.filter.startDate));
            this.startTimeFilterCtrl.setValue(
                this.datePipe.transform(this.filter.startDate, 'HH:mm')
            );
        }

        if (this.filter.endDate) {
            const endDate = new Date(this.filter.endDate);
            const timezoneOffsetE = endDate.getTimezoneOffset() * 60000;
            this.filter.endDate = new Date(endDate.getTime() - timezoneOffsetE);
            this.endDateFilterCtrl.setValue(new Date(this.filter.endDate));
            this.endTimeFilterCtrl.setValue(
                this.datePipe.transform(this.filter.endDate, 'HH:mm')
            );
        }

        this.startTimeFilterCtrl.valueChanges.subscribe((time) => {
            this.setStartTime(time);
        });

        this.endTimeFilterCtrl.valueChanges.subscribe((time) => {
            this.setEndTime(time);
        });

        this.startDateFilterCtrl.valueChanges.subscribe((date) => {
            this.filter.startDate = date;
            if (this.startTimeFilterCtrl.value) {
                this.setStartTime(this.startTimeFilterCtrl.value);
            }
        });

        this.endDateFilterCtrl.valueChanges.subscribe((date) => {
            this.filter.endDate = date;
            if (this.endTimeFilterCtrl.value) {
                this.setEndTime(this.endTimeFilterCtrl.value);
            }
        });
    }

    ngOnDestroy() {
        this.filteredDevices.unsubscribe();
        this.filteredOEMs.unsubscribe();
        this.filteredDealers.unsubscribe();
        this.filteredClient.unsubscribe();
    }

    setStartTime(time) {
        this.filter.startDate = new Date(this.filter.startDate).setHours(
            time.substr(0, 2)
        );
        this.filter.startDate = new Date(this.filter.startDate).setMinutes(
            time.substr(3, 5)
        );
    }

    setEndTime(time) {
        this.filter.endDate = new Date(this.filter.endDate).setHours(
            time.substr(0, 2)
        );
        this.filter.endDate = new Date(this.filter.endDate).setMinutes(
            time.substr(3, 5)
        );
    }

    filterList(list, ctrl, filtered, field) {
        if (!list) {
            return;
        }

        let search = ctrl.value;
        let isFiltered = false;

        if (!search) {
            filtered.next(list.slice());
            isFiltered = true;
            return;
        } else {
            search = search.toLowerCase();
            if ('none'.indexOf(search) > -1) {
                isFiltered = true;
            } else {
                isFiltered = false;
            }
        }

        filtered.next(
            list.filter((obj) => obj[field].toLowerCase().indexOf(search) > -1)
        );

        return isFiltered;
    }

    makeOemList() {
        this.showOem = true;
        this.oemList = this.api.organizations.current({
            role: CustomerRole.OEM,
        });
        this.filteredOEMs.next(this.oemList.slice());
    }

    makeDealerList() {
        this.showDealer = true;
        this.dealerList = this.api.organizations.current({
            role: CustomerRole.Dealer,
        });
        this.filteredDealers.next(this.dealerList.slice());
    }

    makeClientList() {
        this.showClient = true;
        this.clientList = this.api.organizations.current({
            role: CustomerRole.Client,
        });
        this.filteredClient.next(this.clientList.slice());
    }

    applyCurrentFilters() {
        for (const key in this.data['current']) {
            this.filter[key] = this.data['current'][key];
        }
    }

    confirm() {
        const returnFilter = {};
        if (this.filter.deviceId)
            returnFilter['deviceId'] = this.filter.deviceId;
        if (this.filter.oemId) returnFilter['oemId'] = this.filter.oemId;
        if (this.filter.dealerId)
            returnFilter['dealerId'] = this.filter.dealerId;
        if (this.filter.clientId)
            returnFilter['clientId'] = this.filter.clientId;
        if (this.filter.fileType)
            returnFilter['fileType'] = this.filter.fileType;
        if (this.filter.startDate) {
            const startDate = new Date(this.filter.startDate);
            const timezoneOffsetS = startDate.getTimezoneOffset() * 60000;
            returnFilter['startDate'] = new Date(
                startDate.getTime() + timezoneOffsetS
            );
        }
        if (this.filter.endDate) {
            const endDate = new Date(this.filter.endDate);
            const timezoneOffsetE = endDate.getTimezoneOffset() * 60000;
            returnFilter['endDate'] = new Date(
                endDate.getTime() + timezoneOffsetE
            );
        }
        this.dialogRef.close(returnFilter);
    }
}
