<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <div class="flex flex-col items-stretch gap-2">
        <mat-form-field subscriptSizing="dynamic">
            <mat-select
                placeholder="{{ 'data_files.device' | translate }}"
                [(ngModel)]="filter.deviceId"
                name="deviceId"
            >
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="deviceFilterCtrl"
                        [placeholderLabel]="'dialog.search_device' | translate"
                        [noEntriesFoundLabel]="
                            'dialog.no_device_found' | translate
                        "
                        ngDefaultControl
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="isFilterDeviceNone">{{
                    'dialog.none' | translate
                }}</mat-option>
                <mat-option
                    *ngFor="let dev of filteredDevices | async"
                    [value]="dev.id"
                >
                    {{ dev.identifier }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="showOem">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>
                    {{ 'customer.role.oem' | translate }}
                </mat-label>
                <mat-select [(ngModel)]="filter.oemId" name="oemId">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="oemFilterCtrl"
                            [placeholderLabel]="'dialog.search_oem' | translate"
                            [noEntriesFoundLabel]="
                                'dialog.no_oem_found' | translate
                            "
                            ngDefaultControl
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterOEMNone">{{
                        'dialog.none' | translate
                    }}</mat-option>
                    <mat-option
                        *ngFor="let oem of filteredOEMs | async"
                        [value]="oem.id"
                    >
                        {{ oem.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="showDealer">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>
                    {{ 'customer.role.dealer' | translate }}
                </mat-label>
                <mat-select [(ngModel)]="filter.dealerId" name="dealerId">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="dealerFilterCtrl"
                            [placeholderLabel]="
                                'dialog.search_dealer' | translate
                            "
                            [noEntriesFoundLabel]="
                                'dialog.no_dealer_found' | translate
                            "
                            ngDefaultControl
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterDealerNone">{{
                        'dialog.none' | translate
                    }}</mat-option>
                    <mat-option
                        *ngFor="let dealer of filteredDealers | async"
                        [value]="dealer.id"
                    >
                        {{ dealer.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="showClient">
            <mat-form-field subscriptSizing="dynamic">
                <mat-select
                    placeholder="{{ 'customer.role.client' | translate }}"
                    [(ngModel)]="filter.clientId"
                    name="clientId"
                >
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="clientFilterCtrl"
                            [placeholderLabel]="
                                'dialog.search_client' | translate
                            "
                            [noEntriesFoundLabel]="
                                'dialog.no_client_found' | translate
                            "
                            ngDefaultControl
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterClientNone">{{
                        'dialog.none' | translate
                    }}</mat-option>
                    <mat-option
                        *ngFor="let client of filteredClient | async"
                        [value]="client.id"
                    >
                        {{ client.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <mat-form-field
            *ngIf="includeFilter['fileType']"
            subscriptSizing="dynamic"
        >
            <input
                matInput
                type="text"
                placeholder="{{ 'data_files.file_type' | translate }}"
                [(ngModel)]="filter.fileType"
            />
            <button
                *ngIf="filter.fileType"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="filter.fileType = ''"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field
            *ngIf="includeFilter['startDate']"
            subscriptSizing="dynamic"
        >
            <input
                matInput
                [matDatepicker]="startDate"
                [max]="maxDate"
                placeholder="{{ 'dialog.start_date' | translate }}"
                [formControl]="startDateFilterCtrl"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <ng-container *ngIf="filter.startDate">
            <mat-form-field subscriptSizing="dynamic">
                <input
                    matInput
                    type="time"
                    placeholder="{{ 'dialog.start_time' | translate }}"
                    [formControl]="startTimeFilterCtrl"
                />
            </mat-form-field>
        </ng-container>
        <mat-form-field
            *ngIf="includeFilter['endDate']"
            subscriptSizing="dynamic"
        >
            <input
                matInput
                [matDatepicker]="endDate"
                [max]="maxDate"
                placeholder="{{ 'dialog.end_date' | translate }}"
                [formControl]="endDateFilterCtrl"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
        <ng-container *ngIf="filter.endDate">
            <mat-form-field subscriptSizing="dynamic">
                <input
                    matInput
                    type="time"
                    placeholder="{{ 'dialog.end_time' | translate }}"
                    [formControl]="endTimeFilterCtrl"
                />
            </mat-form-field>
        </ng-container>
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="
            !filter.deviceId &&
            !filter.oemId &&
            !filter.dealerId &&
            !filter.clientId &&
            !filter.fileType &&
            !filter.startDate &&
            !filter.endDate
        "
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
